import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PaymentResponse} from "../types";
import {PaymentRequest} from "./Payment";
import {paymentResponse} from "./Navigation";

interface ReceiptState {
    code: string | undefined,
    reason: string | undefined,
}

const initialState = {
    reason: "We were unable to process this transaction based on the details you provided.",
    code: ""
} as ReceiptState;

const slice = createSlice({
    name: "Error",
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(paymentResponse, (state, action: PayloadAction<PaymentResponse>) => {
            const message = action.payload;
            if (message.status !== "declined" && message.status !== "rejected")
                return state;

            state.reason = message.reason;
            state.code = message.code;
            return state;
        });
        builder.addCase(PaymentRequest.fulfilled, (state, action) => {
            const response = action.payload;
            if (response?.status !== "accepted") {
                state.reason = "We were unable to process this transaction based on the details you provided.";
                state.code = "";
            }
            return state;
        });
    }
});

export default slice.reducer;

export type Application = "Wallet" | "BuyAndSend";

export interface MerchantRequest {
    application?: Application | undefined,
    amount?: string | undefined,
    currency?: string | undefined,
    asset?: CryptoCurrency | undefined,
    payee?: string | undefined,
    givenName?: string | undefined,
    lastName?: string | undefined,
    email?: string | undefined,
    phone?: string | undefined,
    dateOfBirth?: string | undefined,
    street?: string | undefined,
    city?: string | undefined,
    state?: string | undefined,
    country?: string | undefined,
    countryName?: string | undefined,
    postcode?: string | undefined,
    returnUrl?: string | undefined,
    occupation?: string | undefined,
    industry?: string | undefined,
    skipLogin?: boolean | undefined,
    enableEditPayeeInput?: boolean | undefined,
    enableEditAmountInput?: boolean | undefined,
    realm: string | undefined
}

export interface CreditCard {
    name_on_card: string | undefined;
    card_number: string | undefined;
    expiry: string | undefined;
    cvv: string | undefined;
}

export interface EmptyPaymentDetail {

}

export interface Account {
    account_number: string | undefined
}

export interface NamedAccount {
    account_name: string | undefined
}

export type MethodType = "banktransfer" | "creditcard" | "mastercard" | "visa" | "flexepin" | "interac" | "emt" | "payid" | "pix" | "UPI_India";

export interface Icon {
    style: string | undefined,
    src: string | undefined
}

export interface IconSet {
    icon: Icon | undefined,
    altIcon: Icon | undefined
}

export interface IconTag {
    methodIndex: number,
    type: string,
    param: string
}

export type ResourceTag = IconTag | undefined;

export interface TaggedResource {
    data: string | undefined,
    tag: ResourceTag
}

export interface ResourceSpecifier {
    methodIndex: number,
    iconType: string,
    iconParam: string
}

export interface LimitSpecifier {
    method: MethodType,
    asset: CryptoCurrency | undefined
}

export interface StringDictionary<T> {
    [i: string]: T;
}

export type CryptoDictionary<T> = {
    [i in CryptoCurrency]: T;
};

export type LimitType = "TransactionLimitForIdVerified"
| "MonthlyLimitForIdVerified"
| "MonthlyLimitForIdNotVerified"
| "MinimumLimit";

export interface Currency {
    code: string | undefined;
    precision: number | undefined;
    name: string | undefined;
    symbol: string | undefined;
}

export const cryptoCurrencies = ["BTC", "LTC", "ETH", "TRX", "USDT", "USDC"] as const;
export type CryptoCurrency = typeof cryptoCurrencies[number];

export interface MethodLimit {
    current: Limit,
    verified: Limit
}

export interface Limit {
    limitType: LimitType | undefined;
    minimumAmount: number | undefined;
    formattedMinimumAmount: string | undefined;
    limitAmount: number | undefined;
    formattedLimitAmount: string | undefined;
    remainAmount: number | undefined;
    formattedRemainAmount: string | undefined;
    usedAmount: number | undefined;
    formattedUsedAmount: string | undefined;
    currency: Currency | undefined;
    txRemainingLimitCount: number | undefined;
    unsatisfiable: boolean | undefined;
}

export interface LimitEnvelope {
    limit: MethodLimit,
    method: MethodType
}

export interface Method {
    id: MethodType | undefined;
    name: string | undefined;
    fee: string | undefined;
    time: string | undefined;
    icon: Icon | undefined;
    altIcon: Icon | undefined;
    totalAmount: string | undefined;
    savedPANs: StringDictionary<string> | undefined;
    save: boolean;
    useSaved: string | undefined;
    limit: MethodLimit | undefined;
    pendingMessage: string | undefined;
}

export interface Currency {
    code: string | undefined,
    precision: number | undefined,
    symbol: string | undefined,
    name: string | undefined
}

export interface Country {
    name: string | undefined;
    alpha2: string | undefined;
    alpha3: string | undefined;
    code: number | undefined;
}

export interface PaymentResponse {
    status: PaymentStatus,
    code?: ReasonCode | undefined,
    reason?: string | undefined,
    "payment-method"?: string | undefined,
    "sell-amount"?: string | undefined,
    "exchange-rate-amount"?: string | undefined,
    "network-fee-paid-amount"?: string | undefined,
    "buy-amount"?: string | undefined,
    "deposit-fee-amount"?: string | undefined,
    "total-amount-paid"?: string | undefined,
    payee?: string | undefined,
    "blockchain-id"?: string | undefined,
    "transaction-id"?: string | undefined,
    merchant?: string | undefined,
    descriptor?: string | undefined,
    limit: LimitDescription | undefined,
    currency: string | undefined,
    "buy-currency"?: string | undefined

}

export interface LimitDescription {
    method?: string | undefined,
    lower?: string | undefined,
    upper?: string | undefined,
    used?: string | undefined,
    remaining?: string | undefined,
    unsatisfiable?: boolean | undefined,
    txremaininglimitcount?: string | undefined
}

export type ReasonCode =
    "Incomplete"
    | "CurrentlyUnableToProcess"
    | "SmsOtpSendQuietPeriod"
    | "SmsOtpCodeMismatch"
    | "BelowMinimum"
    | "AboveMaximum"
    | "InvalidPayee"
    | "HighRiskPayee"
    | "BadIdentity"
    | "SmsOtpVerifyMaximumAttempts"
    | "SmsOtpSendMaximumAttempts"
    | "SmsOtpSendFailure"
    | "UnableToSendCrypto"
    | "LimitCount"
    | "LimitAmount"
    | "Declined"
    | "UnableToQuoteCrypto"
    | "SessionUsed"
    | "AccountClosed"
    | "EmailOtpSendQuietPeriod"
    | "EmailOtpCodeMismatch"
    | "EmailOtpVerifyMaximumAttempts"
    | "EmailOtpSendMaximumAttempts"
    | "EmailOtpSendFailure"
    | "InvalidPassword";

export type PaymentStatus = "accepted" | "rejected" | "declined" | "approved";

export type ChallengeType = "user" | "payid" | "personal-details" | "sms-code" | "email-code" | "instructions" | "html-redirect" | "url-redirect" | "maintenance" | "payment" | "signin-by-email" | "set-password" | "signin-by-password";

export interface ChallengeMessage {
    type: ChallengeType | undefined;
    user_id?: string | undefined;
    expires_in_seconds?: number | undefined;
    phone_number?: string | undefined;
    method?: MethodType | undefined;
    instructions?: string | undefined;
    icon?: Icon | undefined;
    html_page?: string | undefined;
    url_redirect?: string | undefined;
    prompt?: string | undefined;
    reason?: string;
    created?: boolean | undefined;
    currencySymbol?: string | undefined;
    amount?: string | undefined;
    address?: string | undefined;
    user_name?: string | undefined;
    email?: string | undefined;
    realm?: string | undefined;
    features?: string[];
}

export interface CurrencyAmount {
    major_unit_quantity: number,
    minor_unit_quantity: number,
    currency: string,
    display_amount: string
}

export interface QuoteMessage {
    application: Application,
    merchant?: string | undefined,
    amount?: CurrencyAmount | undefined,
    sell: CurrencyAmount,
    buy: CurrencyAmount,
    payee?: string | undefined,
    asset: CryptoCurrency | undefined
}

export interface PersonalDetails {
    givenName?: string | undefined,
    surname?: string | undefined,
    mobile?: string | undefined,
    dob?: string | undefined,
    address?: string | undefined,
    city?: string | undefined,
    state?: string | undefined,
    postcode?: string | undefined,
    occupation?: string | undefined,
    industry?: string | undefined,
    taxId?: string | undefined,
    realm?: string | undefined
}

export interface SessionUpdateMessage {
    status?: "completed" | "expired" | undefined
}

export type SmsFeatures = "ReturnToPaymentOptions";

export interface MethodSave {
    method: string,
    save: boolean
}

export interface MethodUseSaved {
    method: string,
    useSaved: string
}

export type Field = "amount" | "method" | "payee" | "code";

export interface Reason {
    field?: Field | undefined,
    message?: string | undefined
}

export interface WindowMessage {
    type: string,
    message: string
}

export interface UserVerifiedResponse {
    isIdVerified: boolean | undefined,
    isEnabled: boolean | undefined
}

export interface QuoteDetails {
    minor_unit_quantity: number,
    major_unit_quantity: number,
    display_amount: string,
    currency: string
}

export interface QuoteResponse {
    id: string,
    timestamp: string,
    transactionId: string,
    sell: QuoteDetails,
    buy: QuoteDetails,
    rate: number
}

export interface NetworkFeeResponse {
    amount: number,
    currency: Currency,
    formattedAmount: string
}

export interface CryptoCurrencySettings {
    currency: Currency,
    minSale: number,
    maxSale: number,
    minBuy: number,
    maxBuy: number,
    network: string
}

import {AnyAction, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    Account,
    Application,
    NamedAccount,
    CreditCard,
    EmptyPaymentDetail,
    Icon,
    IconSet,
    Method,
    MethodSave,
    MethodType,
    MethodUseSaved,
    MethodLimit,
    Limit,
    LimitEnvelope,
    ChallengeMessage,
    PaymentResponse,
    Reason,
    LimitSpecifier,
    TaggedResource,
    Currency,
    CryptoCurrency,
    CryptoDictionary,
    QuoteResponse,
    NetworkFeeResponse,
    CryptoCurrencySettings
} from "../types";
import {PaymentChannel, PaymentChannelEnvelope} from "../../features/PaymentProvider";
import {tryAgain} from "./Navigation";
import {RootState} from "../store";
import {IsNullOrEmpty} from "../strings";
import {connected} from "../actions";
import {updateDeviceId} from "./PersonalDetails";
import Numbers from "../../utils/Numbers";
import Address from "../../utils/Address";

interface PaymentState {
    application?: Application | undefined,
    loading: boolean,
    processing: boolean,
    submitDisabled: boolean,
    methods: Method[] | undefined,
    currentMethod: Method | undefined,
    currency: Currency | undefined,
    cryptoCurrencies: CryptoDictionary<CryptoCurrencySettings> | undefined,
    asset: CryptoCurrency | undefined,
    quote: QuoteResponse | undefined,
    rate: number | undefined,
    formattedRate: string | undefined,
    formattedNetworkFee: string | undefined,
    deviceId: string | undefined
    amount: number | undefined,
    amountReadOnly: boolean | undefined,
    payee: string | undefined,
    payeeReadonly: boolean,
    method: MethodType | undefined,
    creditCard: CreditCard | undefined,
    account: Account | undefined,
    namedAccount: NamedAccount | undefined,
    errors: Reason[],
    skipLogin: boolean | undefined,
    limitsLoaded: boolean,
    iconsLoaded: boolean,
    updatingTotalAmount: boolean,
    countryName: string | undefined
}

export const LoadLimit = createAsyncThunk<MethodLimit | undefined, PaymentChannelEnvelope, { state: RootState }>(
    "payment/loadLimit",
    (envelope) => {
        const parameters = envelope.parameters as LimitSpecifier;

        if (parameters.method === undefined)
            return Promise.resolve<MethodLimit | undefined>(undefined);

        return envelope.channel.getLimit(parameters.method, parameters.asset);
    }
);

export const LoadLimits = createAsyncThunk<(MethodLimit | undefined)[] | undefined, PaymentChannel, { state: RootState }>(
    "payment/loadLimits",
    (channel, ThunkAPI) => {
        const asset = ThunkAPI.getState().payment.asset;
        const methods = ThunkAPI.getState().payment.methods;
        if (!methods)
            return Promise.resolve(undefined);

        const promises = methods.map(method => {
            if (!method.id)
                return Promise.resolve(undefined);

            return channel.getLimit(method.id, asset);
        });
        return Promise.all(promises);
    }
);

export const LoadIcons = createAsyncThunk<TaggedResource[] | undefined, PaymentChannel, { state: RootState }>(
    "payment/loadIcons",
    async (channel, ThunkAPI) => {
        const methods = ThunkAPI.getState().payment.methods;
        if (!methods)
            return Promise.resolve(undefined);

        const promises : Promise<TaggedResource>[] = [];

        methods.map((method, index) => {
            if (method.icon?.src !== undefined)
                promises.push(channel.getResource(method.icon.src,
                    {methodIndex: index, type: "icon", param: "src"}
                ));

            if (method.icon?.style !== undefined)
                promises.push(channel.getResource(method.icon.style,
                    {methodIndex: index, type: "icon", param: "style"}
                ));

            if (method.altIcon?.src !== undefined)
                promises.push(channel.getResource(method.altIcon.src,
                    {methodIndex: index, type: "altIcon", param: "src"}
                ));

            if (method.altIcon?.style !== undefined)
                promises.push(channel.getResource(method.altIcon.style,
                    {methodIndex: index, type: "altIcon", param: "style"}
                ));

            return null;
        });

        return Promise.all(promises);
    }
);

export const loadMethods = createAsyncThunk<Method[], PaymentChannel, { state: RootState }>(
    "payment/methods",
    (channel) => {
        return channel.getMethods();
    }
);

export const LoadEnabledCryptoCurrencies = createAsyncThunk<CryptoDictionary<CryptoCurrencySettings>, PaymentChannel, { state: RootState }>(
    "payment/cryptoCurrencies",
    (channel) => {
        return channel.getEnabledCryptoCurrencies();
    }
);

export const LoadCurrencyInfo = createAsyncThunk<Currency, PaymentChannel, { state: RootState }>(
    "payment/currency",
    (channel) => {
        return channel.getCurrency();
    }
);

export const GetQuote = createAsyncThunk<QuoteResponse | undefined, PaymentChannel, { state: RootState }>(
    "payment/quote",
    (channel, ThunkAPI) => {
        const state = ThunkAPI.getState().payment;

        if (state.currency === undefined)
            return Promise.resolve(undefined);

        const sellcurrencyamount = state.amount ?? 0;
        const sellcurrency = state.currency?.code || "";
        const purchasecurrencyamount = 0;
        const purchasecurrency = state.asset ?? "";
        const type = "sell";

        return channel.getQuote(sellcurrencyamount, sellcurrency, purchasecurrencyamount, purchasecurrency, type);
    }
);

export const GetEstimatedNetworkFee = createAsyncThunk<NetworkFeeResponse | undefined, PaymentChannel, { state: RootState }>(
    "payment/estimatedNetworkFee",
    (channel, ThunkAPI) => {
        const state = ThunkAPI.getState().payment;
        const assetId = state.asset ?? "";
        const currency = state.currency?.code || "";

        if (state.quote === undefined || state.quote.rate === undefined || state.quote.rate <= 0)
            return Promise.resolve(undefined);

        const rate = 1 / state.quote.rate;

        return channel.getEstimatedNetworkFee(assetId, rate, currency);
    }
);

export const CalculateTotalAmounts = createAsyncThunk<string[] | undefined, PaymentChannel, { state: RootState }>(
    "payment/totalAmounts",
    (channel, ThunkAPI) => {
        const state = ThunkAPI.getState().payment;
        const methods = state.methods;
        if (!methods)
            return Promise.resolve(undefined);

        const requestAmount = state.amount;
        if (!requestAmount)
            return Promise.resolve(undefined);

        const promises = methods.map(method => {
            if (!method.id)
                return Promise.resolve("");

            return channel.calculateTotalAmount(method.id, requestAmount)
                .then(totalAmount => totalAmount);
        });
        return Promise.all(promises);
    }
);

export const PaymentRequest = createAsyncThunk<PaymentResponse | undefined, PaymentChannel, { state: RootState, rejectValue: Reason[] }>(
    "payment/request",
    (channel, ThunkAPI) => {
        const payment = ThunkAPI.getState().payment;
        const deviceId = payment.deviceId;
        const method = payment.method;
        const amount = payment.amount;
        const payee = payment.payee;
        const asset = payment.asset;
        const application = payment.application;

        const reasons = [] as Reason[];
        if (!method)
            reasons.push({field: "method", message: "Please choose a payment method."} as Reason);

        if (!amount || amount === 0)
            reasons.push({field: "amount", message: "Please enter an amount."} as Reason);

        if (application === 'BuyAndSend' && (!payee || payee.length <= 0))
            reasons.push({field: "payee", message: `Please enter a valid ${asset} address.`} as Reason)

        if (reasons.length > 0) {
            return ThunkAPI.rejectWithValue(reasons)
        }

        const details = SelectPaymentDetails(payment, method);
        return channel.requestPayment(method, amount, payee, asset, details, deviceId);
    }
);

export const CancelPayment = createAsyncThunk<void, PaymentChannel, { state: RootState }>(
    "payment/cancel",
    (channel) => {
        return channel.cancelPayment();
    }
);

export const TryAnotherMethod = createAsyncThunk<void, PaymentChannel, { state: RootState }>(
    "payment/tryAnotherMethod",
    (channel) => {
        return channel.tryAnotherMethod();
    }
);

function SelectPaymentDetails(state: PaymentState, method: MethodType | undefined): CreditCard | Account | NamedAccount | EmptyPaymentDetail | undefined {
    if (!method)
        return undefined;

    switch (method) {
        case "mastercard":
        case "visa":
            return state.creditCard;


        case "flexepin":
            return state.account

        case "banktransfer":
        case "emt":
        case "interac":
        case "payid":
        case "UPI_India":
        case "creditcard":
        case 'pix':
            return {} as EmptyPaymentDetail;

        default:
            return undefined;
    }
}

function IsTryAgain(action: AnyAction): boolean {
    return action.type === tryAgain.type;
}

function IsMethodComplete(state: PaymentState): boolean {
    switch (state.method) {
        case "visa":
        case "mastercard":
            const expiryRegExp = new RegExp('^(0[0-9]|1[0-2])[0-9][0-9]$');
            const now = new Date();
            const expiryMin = now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, '0');
            const expiry = state?.creditCard?.expiry
                ? expiryMin.substr(0, 2) + state.creditCard.expiry.substr(2, 2) + state.creditCard.expiry.substr(0, 2)
                : "000000";
            return state.creditCard !== undefined
                && !IsNullOrEmpty(state.creditCard.name_on_card)
                && /^\d{13,16}$/.test(state.creditCard.card_number ?? "")
                && expiryRegExp.test(state.creditCard.expiry ?? "")
                && expiry >= expiryMin
                && !IsNullOrEmpty(state.creditCard.cvv)
                && /^\d{3,4}$/.test(state.creditCard.cvv ?? "");

        case "flexepin":
            return state.account !== undefined
                && !IsNullOrEmpty(state.account.account_number)
                && /^\d{16}$/.test(state.account.account_number ?? "");

        default:
            return true;
    }
}

function IsAmountValid(state: PaymentState): boolean {
    if (state.method === undefined || state.methods === undefined || state.amount === undefined)
        return false;

    const method = state.methods.find(m => m.id === state.method);
    if (method === undefined)
        return false;

    const selectedLimit = SelectLimit(method.limit, state.amount, state.currency);
    const limit = selectedLimit ? selectedLimit[0] : undefined;
    if (limit === undefined || limit.remainAmount === undefined || limit.minimumAmount === undefined)
        return true;

    const currencyPrecision =  state.currency?.precision;
    if (currencyPrecision === undefined)
        return false;

    state.errors = state.errors.filter(e => e.field !== "amount")

    const errors = [] as Reason[];
    const amountCents = parseInt((state.amount * Math.pow(10, currencyPrecision)).toFixed(0), 10);
    if (amountCents > limit.remainAmount) {
        errors.push({
            field: "amount",
            message: `This transaction will exceed your remaining limit of ${limit.formattedRemainAmount}. Please enter a smaller amount.`
        } as Reason);
    }

    if (amountCents < limit.minimumAmount) {
        errors.push({
            field: "amount",
            message: `The minimum amount for this transaction is ${limit.formattedMinimumAmount}. Please enter a larger amount.`
        } as Reason);
    }

    state.errors.push(...errors);

    return errors.length === 0;
}

export function SelectLimit(methodLimits: MethodLimit | undefined,
                            amount: number | undefined,
                            currency: Currency | undefined
                           ): [Limit, boolean] | undefined {
    if (methodLimits === undefined)
        return undefined;

    if (amount === undefined || currency?.precision === undefined)
        return [methodLimits.current, false];

    const amountCents = parseInt((amount * Math.pow(10, currency?.precision)).toFixed(0), 10);
    const ls = [[methodLimits.current, false], [methodLimits.verified, true]] as [Limit, boolean][];
    const candidates = ls.filter(([limit]) => {
         if (!Numbers.GreaterThan(limit.txRemainingLimitCount, 0))
            return false;

        return Numbers.GreaterThanEqual(limit.remainAmount, amountCents);
    });

    if (candidates.length > 0)
        return candidates[0];

    return [methodLimits.current, false];
}

function limitErrorMessage(state: PaymentState, type: string, minFiat: number, minCrypto: number | undefined): string {
    const formattedFiat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: state.currency?.code,
        currencyDisplay: 'narrowSymbol',
        // @ts-ignore: TS2345 Type check for roudingMode (field missing in the TS type definitions for ES2020 Intl: https://github.com/microsoft/TypeScript/issues/52072#issuecomment-1709012036)
        roundingMode: type === 'min' ? 'ceil' : 'floor'
    }).format(minFiat / Math.pow(10, state.currency?.precision ?? 2));
    const comparative = type === 'min' ? "larger" : "smaller";

    if (state.application === "BuyAndSend") {
        if (minCrypto !== undefined && state.asset !== undefined) {
            return `The current ${type}imum amount for this transaction is ${minCrypto} ${state.asset} ≈ ${formattedFiat} ${state.currency?.code}. Please enter a ${comparative} amount.`;
        } else {
            return `The current ${type}imum amount for crypto transactions is ${formattedFiat} ${state.currency?.code}. Please enter a ${comparative} amount.`;
        }
    } else {
        return `The ${type}imum amount for this transaction is ${formattedFiat} ${state.currency?.code}. Please enter a ${comparative} amount.`;
    }
}

function checkInitialAmount(state: PaymentState): void {
    //method selected, no need to do this check
    if (state.method)
        return;

    if (state.methods === undefined || state.methods.length === 0 || state.amount === undefined)
        return;

    state.errors = state.errors.filter(e => e.field !== "amount")

    const errors = [] as Reason[];
    const currencyPrecision = state.currency?.precision;
    if (currencyPrecision === undefined)
        return;

    let fiatMinimum = state.methods.reduce((minValue, item) => {
        const min = item?.limit?.current.minimumAmount;
        if (min && min !== 0 && min < minValue) {
            return min;
        }
        return minValue;
    }, Number.MAX_VALUE);

    let cryptoMinimum;
    if (state.cryptoCurrencies !== undefined) {
        for (var crypto in state.cryptoCurrencies) {
            if (crypto === state.asset) {
                const settings = state.cryptoCurrencies[crypto as CryptoCurrency];
                if (state.rate !== undefined) {
                    cryptoMinimum = settings.minSale;
                    fiatMinimum = Math.max(fiatMinimum, cryptoMinimum * state.rate * Math.pow(10, currencyPrecision));
                }
            }
        }
    }

    const amountCents = parseInt((state.amount * Math.pow(10, currencyPrecision)).toFixed(0), 10);
    if (fiatMinimum !== Number.MAX_VALUE && amountCents < fiatMinimum) {
        const message = limitErrorMessage(state, 'min', fiatMinimum, cryptoMinimum);
        errors.push({
            field: "amount",
            message: message
        } as Reason);
    }

    state.errors.push(...errors);
}

function HasAvailableLimit(limit: Limit): boolean {
    return Numbers.GreaterThan(limit.limitAmount, 0) && Numbers.GreaterThan(limit.remainAmount, 0);
}


function isPayeeAddressValid(state: PaymentState) {
    if (state.payee === undefined || state.payee === "" || state.asset === undefined)
        return false;

    const validityMap = Address.isValid(state.payee);
    const validAny = Address.isValidAny(validityMap);

    state.errors = state.errors.filter(e => e.field !== "payee");
    if (!validityMap[state.asset]) {
        state.errors.push({
            field: "payee",
            message: "Please enter a valid " + state.asset + " address"
                + (validAny ? ", or check your cryptocurrency selection." : ".")
        } as Reason);
    }
    return validityMap[state.asset];
}

function IsSubmitDisabled(state: PaymentState): boolean {
    const amountValid = IsAmountValid(state);
    const payeeAddressValid = isPayeeAddressValid(state);
    return state.loading
        || state.processing
        || state.amount === undefined
        || !amountValid
        || (state.application === 'BuyAndSend' && !payeeAddressValid)
        || state.method === undefined
        || !IsMethodComplete(state);
}

const initialState = {
    methods: undefined,
    loading: false,
    processing: false,
    submitDisabled: true,
    amountReadOnly: false,
    payeeReadonly: false,
    limitsLoaded: false,
    iconsLoaded: false,
    updatingTotalAmount: false,
    errors: [] as Reason[]
} as PaymentState

const slice = createSlice({
    name: 'payment',
    initialState: initialState,
    reducers: {
        updateAccount: (state, action: PayloadAction<Account>) => {
            state.account = action.payload;
            state.submitDisabled = IsSubmitDisabled(state);
            return state;
        },
        updateNamedAccount: (state, action: PayloadAction<NamedAccount>) => {
            state.namedAccount = action.payload;
            state.submitDisabled = IsSubmitDisabled(state);
            return state;
        },
        updateAmount: (state, action: PayloadAction<number | undefined>) => {
            state.amount = action.payload;
            state.submitDisabled = IsSubmitDisabled(state);
            return state;
        },
        checkAmount: (state) => {
            checkInitialAmount(state);
            return state;
        },
        updateCreditCard: (state, action: PayloadAction<CreditCard>) => {
            state.creditCard = action.payload;
            state.submitDisabled = IsSubmitDisabled(state);
            return state;
        },
        updateMethod: (state, action: PayloadAction<MethodType | undefined>) => {
            state.method = action.payload;
            state.methods?.map((method) => {
                if (method.id === state.method) {
                    state.currentMethod = method;
                }
                return null;
            });
            state.submitDisabled = IsSubmitDisabled(state);
            return state;
        },
        updatePayee: (state, action: PayloadAction<string>) => {
            state.payee = action.payload;
            state.submitDisabled = IsSubmitDisabled(state);
            return state;
        },
        updateAsset: (state, action: PayloadAction<CryptoCurrency>) => {
            state.asset = action.payload;
            state.submitDisabled = IsSubmitDisabled(state);
            return state;
        },
        toggleSavePaymentDetails: (state, action: PayloadAction<MethodSave>) => {
            let methods = state.methods;
            let change = action.payload;

            methods = methods?.map(method => {
                if (method.id === change.method) {
                    method.save = change.save;
                }
                return method;
            });

            state.methods = methods;
            return state;
        },
        selectSavedMethod: (state, action: PayloadAction<MethodUseSaved>) => {
            let methods = state.methods;
            const change = action.payload;

            methods = methods?.map(method => {
                if (method.id === change.method) {
                    method.useSaved = change.useSaved;
                }
                return method;
            });

            state.methods = methods;
            return state;
        },
        setProcessing: (state, action: PayloadAction<boolean>) => {
            state.processing = action.payload;
            return state;
        },
        setLimit: (state, action: PayloadAction<LimitEnvelope>) => {
            const payload = action.payload;

            if (state.methods !== undefined) {
                state.methods = state.methods?.map(method => {
                    if (method.id === payload.method)
                        method.limit = payload.limit;
                    return method;
                });
            }
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(connected, (state, action) => {
            state.application = action.payload.application;
            state.payee = action.payload.payee ?? state.payee;
            state.asset = action.payload.asset ?? state.asset;
            state.amount = state.amount ?? (action.payload.amount ? Number(action.payload.amount) : undefined);
            state.amountReadOnly = !!action.payload.amount && !action?.payload.enableEditAmountInput;
            state.payeeReadonly = !!action.payload.payee && !action?.payload.enableEditPayeeInput;
            state.submitDisabled = IsSubmitDisabled(state);
            state.countryName = action?.payload.countryName;
            state.skipLogin = action.payload.skipLogin ?? false;
        });

        builder.addCase(loadMethods.pending, (state) => {
            state.loading = true;
            state.iconsLoaded = false;
            state.limitsLoaded = false;
            return state;
        });

        builder.addCase(loadMethods.fulfilled, (state, action) => {
            state.methods = action.payload;
            state.loading = false;
            return state;
        });

        builder.addCase(loadMethods.rejected, (state) => {
            state.loading = false;
            return state;
        });

        builder.addCase(LoadEnabledCryptoCurrencies.fulfilled, (state, action) => {
            state.cryptoCurrencies = action.payload;
            let asset = "BTC" as CryptoCurrency;
            for (const [key, value] of Object.entries(action.payload)) {
                asset = key as CryptoCurrency;
                break;
            }
            state.asset = state.asset ?? asset;
            return state;
        });

        builder.addCase(GetQuote.fulfilled, (state, action) => {
            const quote = action.payload;
            state.quote = quote;
            state.formattedRate = undefined;
            state.formattedNetworkFee = undefined;

            if (quote === undefined || quote.rate === undefined || quote.rate <= 0)
                return state;

            const rate = 1 / quote.rate;
            const formattedCurrency = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: quote?.sell.currency
            }).format(rate);

            state.rate = rate;
            state.formattedRate = '1 ' + quote?.buy.currency + ' ≈ ' + formattedCurrency;

            return state;
        });

        builder.addCase(GetEstimatedNetworkFee.fulfilled, (state, action) => {
            const networkFee = action.payload;
            if (networkFee === undefined
                || networkFee.amount === undefined
                || networkFee.amount <= 0
                || networkFee.currency === undefined
                || networkFee.currency.precision === undefined) {
                state.formattedNetworkFee = undefined;
                return state;
            }

            const minorUnit = Math.pow(10, -networkFee.currency.precision);
            if (networkFee.amount < minorUnit)
                state.formattedNetworkFee = `< ${networkFee.currency.symbol}${minorUnit} ${networkFee.currency.code}`;
            else
                state.formattedNetworkFee = `≈ ${networkFee.formattedAmount}`;
            return state;
        });

        builder.addCase(CalculateTotalAmounts.pending, (state) => {
            state.updatingTotalAmount = true;
            return state;
        });

        builder.addCase(CalculateTotalAmounts.fulfilled, (state, action) => {
            const totalAmounts = action.payload;
            state.methods = state.methods?.map((method, index) => {
                method.totalAmount = (totalAmounts && totalAmounts[index]) || undefined;
                return method;
            });
            state.updatingTotalAmount = false;
            return state;
        });

        builder.addCase(CalculateTotalAmounts.rejected, (state) => {
            state.updatingTotalAmount = false;
            return state;
        });

        builder.addCase(LoadCurrencyInfo.fulfilled, (state, action) => {
            state.currency = action.payload;
            return state;
        })

        builder.addCase(LoadLimits.fulfilled, (state, action) => {
            const limits = action.payload;
            state.methods = state.methods
                ?.map((method, index) => {
                    method.limit = (limits && limits[index]) || undefined;
                    return method;
                })
                .filter(method =>
                        method.limit !== undefined &&
                        method.limit !== null  &&
                        (HasAvailableLimit(method.limit.current) || HasAvailableLimit(method.limit.verified)));
                state.limitsLoaded = true;
            return state;
        });

        builder.addCase(LoadIcons.fulfilled, (state, action) => {
            const resources = action.payload;

            let icons : IconSet[] = [];
            resources?.map(resource => {
                let tag = resource.tag;

                if (resource.data !== undefined && tag && tag.methodIndex >= 0) {
                    let iconSet = icons[tag.methodIndex] || {icon: undefined, altIcon: undefined} as IconSet;

                    switch (tag.type) {
                        case 'icon':
                            if (iconSet.icon === undefined)
                                iconSet.icon = {src: undefined, style: undefined} as Icon;

                            switch (tag.param) {
                                case 'src': iconSet.icon.src = "data:image/svg+xml;base64," + resource.data; break;
                                case 'style': iconSet.icon.style = resource.data; break;
                            }
                            break;
                        case 'altIcon':
                            if (iconSet.altIcon === undefined)
                                iconSet.altIcon = {src: undefined, style: undefined} as Icon;

                            switch (tag.param) {
                                case 'src': iconSet.altIcon.src = "data:image/svg+xml;base64," + resource.data; break;
                                case 'style': iconSet.altIcon.style = resource.data; break;
                            }
                            break;
                    }

                    icons[tag.methodIndex] = iconSet;
                }

                return null;
            });

            state.methods = state.methods?.map((method, index) => {
                method.icon = icons[index].icon;
                method.altIcon = icons[index].altIcon;

                return method;
            });

            state.iconsLoaded = true;

            return state;
        });

        builder.addCase(PaymentRequest.pending, (state) => {
            state.loading = true;
            return state;
        });

        builder.addCase(PaymentRequest.fulfilled, (state) => {
            state.loading = false;
            return state;
        });

        builder.addCase(PaymentRequest.rejected, (state, action) => {
            state.errors = action.payload as Reason[];
            state.loading = false;
            return state;
        });

        builder.addCase(updateDeviceId, (state, action) => {
            state.deviceId = action.payload;
            return state;
        });

        builder.addMatcher(IsTryAgain, (state) => {
            state.method = undefined;
            state.creditCard = undefined;
            state.account = undefined;
            state.methods = undefined;
            state.loading = false;
            state.processing = false;
            state.submitDisabled = true;
            state.amountReadOnly = false;
            state.payeeReadonly = false;
            state.limitsLoaded = false;
            state.iconsLoaded = false;
        });

        builder.addMatcher(a => a.type === "Navigation/challenge", (state, _: PayloadAction<ChallengeMessage>) => {
            state.processing = false;
            return state;
        });

        builder.addMatcher(a => a.type === "Navigation/paymentResponse", (state, action: PayloadAction<PaymentResponse>) => {
            state.errors = [] as Reason[];
            state.processing = false;

            const code = action.payload.code;
            const reason = action.payload.reason;
            if (code) {
                switch (code) {
                    case "HighRiskPayee":
                    case "InvalidPayee": {
                        state.errors.push({field: "payee", message: reason} as Reason);
                        break;
                    }

                    case "AboveMaximum":
                    case "BelowMinimum": {
                        state.errors.push({field: "amount", message: reason} as Reason);
                        break;
                    }
                }
            }

            return state;
        });
    }
})

export const {
    updateAccount,
    updateNamedAccount,
    updateAmount,
    updateCreditCard,
    updateMethod,
    updatePayee,
    updateAsset,
    toggleSavePaymentDetails,
    selectSavedMethod,
    setProcessing,
    setLimit,
    checkAmount,
} = slice.actions;

export default slice.reducer;

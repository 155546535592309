import { createAsyncThunk
    , createSlice
    , PayloadAction
} from "@reduxjs/toolkit";
import { Application
    , ChallengeMessage
    , ChallengeType
    , PaymentResponse
    , PaymentStatus
    , ReasonCode
    , Country
    , SessionUpdateMessage
} from "../types";
import { startSession } from "./User";
import { PaymentRequest, TryAnotherMethod } from "./Payment";
import { PaymentChannel } from "../../features/PaymentProvider";
import { RootState } from "../store";
import { connected } from "../actions";


export type Page = "user" | "payid"| "payment" | "personal-details" | "sms-code" | "email-code" | "instructions" |
    "error" | "receipt" | "redirect" | "decline" | "maintenance" | "not-allowed" | "start-over" |
    "start-over-expired" | "signin-by-email" | "set-password" | "signin-by-password" | "change-mobile-number" |
    "change-email" | "forgot-password";
export type ModalPage = "sms-code-sent" | "sms-quiet-period" | "email-code-sent" | "email-quiet-period";

export const LoadIsEnabled = createAsyncThunk<boolean | undefined, PaymentChannel, { state: RootState }>(
    "management/enabled",
    (channel) => {
        return channel.isEnabled();
    }
);

export const CheckCountryAllowed = createAsyncThunk<boolean | undefined, PaymentChannel, { state: RootState }>(
    "navigation/checkCountryAllowed",
    (channel) => {
        return channel.isCountryAllowed();
    }
);

export const GetAvailableBuyAndSendCountries = createAsyncThunk<Country[] | undefined, PaymentChannel, { state: RootState }>(
    "navigation/getAvailableBuyAndSendCountries",
    (channel) => {
        return channel.getAvailableBuyAndSendCountries();
    }
);

export const CheckCustomerAllowedBuyAndSend = createAsyncThunk<boolean | undefined, PaymentChannel, { state: RootState }>(
    "navigation/checkCustomerAllowedBuyAndSend",
    (channel) => {
        return channel.isAllowedBuyAndSend();
    }
);

interface NavigationState {
    application: Application | undefined,
    operatingName: string | undefined,
    page: Page | undefined,
    content?: string | undefined,
    url?: string | undefined,
    showModal?: ModalPage | undefined,
    prompt?: string | undefined,
    isEnabled?: boolean | undefined,
    availableCountries? : Country[] | undefined
}

const initialState = {
    page: undefined,
    isEnabled: true
} as NavigationState;

const slice = createSlice({
    name: "Navigation",
    initialState: initialState,
    reducers: {
        challenge: (state, action: PayloadAction<ChallengeMessage>) => {
            const message = action.payload;
            state.prompt = message.prompt;

            switch (message.type) {
                case 'html-redirect':
                    state.showModal = undefined;
                    state.url = undefined;
                    state.content = message.html_page;
                    state.page = "redirect";
                    break;

                case 'url-redirect':
                    state.showModal = undefined;
                    state.url = message.url_redirect;
                    state.content = undefined;
                    state.page = "redirect"
                    break;

                case 'payment':
                    state.showModal = undefined;
                    state.url = undefined;
                    state.content = undefined;
                    state.page = "payment"
                    break;

                default:
                    const previousPage = state.page;
                    const nextPage = PageFor(message.type);
                    state.showModal = (previousPage === "sms-code" && nextPage === "sms-code") ? "sms-code-sent" : undefined;
                    state.page = nextPage;
                    break;
            }

            return state;
        },
        paymentResponse: (state, action: PayloadAction<PaymentResponse>) => {
            const message = action.payload;
            state.page = PageForPaymentResponse(message.status, message.code) ?? state.page;
            switch (message.code){
                case "SmsOtpSendQuietPeriod":
                    state.showModal = "sms-quiet-period";
                    break;
                case "EmailOtpSendQuietPeriod":
                    state.showModal = "email-quiet-period";
                    break;
                default:
                    state.showModal = undefined;
            }
            return state;
        },
        hideModal: (state) => {
            state.showModal = undefined;
            return state;
        },
        tryAgain: (state) => {
            state.page = "payment";
            return state;
        },
        error: (state) => {
            state.page = "error";
            return state;
        },
        clearPrompt: (state) => {
            state.prompt = undefined;
            return state;
        },
        initiateLogin: (state) => {
            state.page = "user";
            return state;
        },
        sessionUpdate: (state, action: PayloadAction<SessionUpdateMessage>) => {
            const status = action.payload.status;
            state.page = status === "completed" ? "start-over" : "start-over-expired";
            return state;
        },
        maintenance: (state) => {
            state.page = "maintenance";
            return state;
        },
        changeMobileNumber: (state) => {
            state.page = "change-mobile-number";
            return state
        },
        changeEmail: (state) => {
            state.page = "change-email";
            return state
        },
        forgotPassword: (state) => {
            state.page = "forgot-password";
            return state;
        },
        setOperatingName: (state, action: PayloadAction<string>) => {
            state.operatingName = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(connected, (state, action) => {
            state.application = action.payload.application;
            return state;
        });
        builder.addCase(startSession.rejected, (state) => {
            state.page = "error";
            return state;
        });
        builder.addCase(PaymentRequest.fulfilled, (state, action) => {
            const response = action.payload;
            if (response?.status !== "accepted") {
                state.page = "error";
            }
            return state;
        });
        builder.addCase(LoadIsEnabled.fulfilled, (state, action) => {
            const isEnabled = !!action.payload;
            if (!isEnabled) {
                state.page = "maintenance";
            }

            return state;
        });
        builder.addCase(CheckCountryAllowed.fulfilled, (state, action) => {
            const isCountryAllowed = action.payload || false;
            if (!isCountryAllowed && state.page !== "not-allowed") {
                state.page = "not-allowed";
                state.prompt = "We're sorry. " + (state.operatingName ?? "Buy+Send") + " is not currently available in your country.";
            }
            return state;
        });
        builder.addCase(CheckCustomerAllowedBuyAndSend.fulfilled, (state, action) => {
            const isCustomerAllowedBuyAndSend = action.payload || false;
            if (!isCustomerAllowedBuyAndSend && state.page !== "not-allowed") {
                state.page = "not-allowed";
                state.prompt = "We're sorry. " + (state.operatingName ?? "Buy+Send") + " is not currently available to your account.";
            }
            return state;
        });
        builder.addCase(GetAvailableBuyAndSendCountries.fulfilled, (state, action) => {
            const availableCountries = action.payload;
            if (!availableCountries) {
                state.page = "error";
                state.prompt = "We're sorry. An unexpected error has occured.";
            } else {
                state.availableCountries = availableCountries;
            }
            return state;
        });
    }
});

function PageForPaymentResponse(status: PaymentStatus | undefined, code: ReasonCode | undefined) : Page | undefined {
    if (code === "UnableToQuoteCrypto" || code === "UnableToSendCrypto")
        return "maintenance";

    switch (status) {
        case "rejected":
            switch (code) {
                case "HighRiskPayee":
                case "InvalidPayee":
                case "BelowMinimum":
                case "AboveMaximum":
                    return "payment";

                case "CurrentlyUnableToProcess":
                case "SmsOtpSendMaximumAttempts":
                case "SmsOtpSendFailure":
                case "EmailOtpSendMaximumAttempts":
                case "EmailOtpSendFailure":
                case "SessionUsed":
                case "BadIdentity":
                case "AccountClosed":
                    return "error";

                case "SmsOtpSendQuietPeriod":
                case "SmsOtpCodeMismatch":
                case "SmsOtpVerifyMaximumAttempts":
                    return "sms-code";

                case "EmailOtpSendQuietPeriod":
                case "EmailOtpCodeMismatch":
                case "EmailOtpVerifyMaximumAttempts":
                    return "email-code";

                default:
                    return "decline";
            }

        case "declined":
            return "decline";

        case "approved":
            return "receipt";

        case "accepted":
            return undefined;
    }

}

function PageFor(type: ChallengeType | undefined) : Page {
    if (!type)
        return "error";

    switch (type) {
        case "user":
        case "email-code":
        case "signin-by-email":
        case "sms-code":
        case "payid":
        case "personal-details":
        case "instructions":
        case "maintenance":
        case "set-password":
        case "signin-by-password":
            return type as Page;
        default:
            return "error";
    }
}

export const {
    challenge,
    paymentResponse,
    hideModal,
    tryAgain,
    error,
    clearPrompt,
    initiateLogin,
    sessionUpdate,
    maintenance,
    changeMobileNumber,
    changeEmail,
    forgotPassword,
    setOperatingName
} = slice.actions;

export default slice.reducer;

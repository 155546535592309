import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IsNullOrEmpty} from "../strings";
import {PersonalDetails} from "../types";
import {PaymentChannel} from "../../features/PaymentProvider";
import {RootState} from "../store";
import {PhoneChangedEvent} from "../../features/Prompt/PhoneNumber";
import {connected} from "../actions";
import { Objects } from "../../utils/Objects";
import Pay from "../../features/Button/Pay";

interface State {
    submitDisabled: boolean,
    loading: boolean,
    processing: boolean,
    givenName: string | undefined,
    surname: string | undefined,
    mobile: {
        value: string | undefined,
        fullNumber: string | undefined,
        extension: string | undefined,
        isValid: boolean | undefined
    },
    dob: string | undefined,
    address: string | undefined,
    city: string | undefined,
    state: string | undefined,
    postcode: string | undefined,
    taxId?: string | undefined,
    deviceId: string | undefined,       //generated by 4Stop/Acuity
    w88DeviceId: string | undefined,    //generated by us
    errors: {
        mobile?: string | undefined,
        givenName?: string | undefined,
        surname?: string | undefined,
        dob?: string | undefined,
        address?: string | undefined,
        city?: string | undefined,
        state?: string | undefined,
        postcode?: string | undefined,
        occupation?: string | undefined,
        industry?: string | undefined,
        taxId?: string | undefined
    },
    returnUrl: string | undefined,
    occupation: string | undefined,
    industry: string | undefined,
    realm: string | undefined,
    password: string | undefined
}

export const RegistrationRequest = createAsyncThunk<void, PaymentChannel, { state: RootState }>(
    "personalDetails/register",
    (channel, ThunkAPI) => {
        const { givenName, surname, mobile, dob, address, city, state, postcode, deviceId,
            w88DeviceId,occupation, industry, taxId, realm } = ThunkAPI.getState().personalDetails;
        return channel.register({
            mobile: mobile.fullNumber || null,
            givenName: givenName || null,
            surname: surname || null,
            dob: dob?.substr(0, 10) || null,
            address: address || null,
            city: city || null,
            state: state || null,
            postcode: postcode || null,
            device: deviceId || null,
            w88Device: w88DeviceId || null,
            occupation: occupation || null,
            industry: industry || null,
            taxId: taxId || null,
            realm: realm || null
        } as PersonalDetails);
    }
);

export const SetPassword = createAsyncThunk<void | undefined, PaymentChannel, { state: RootState }>(
    "personalDetails/setPassword",
    (channel, ThunkAPI) => {
        const { password } = ThunkAPI.getState().personalDetails;
        return channel.setPassword(password);
    }
);

export const ChangeMobileNumber = createAsyncThunk<void | undefined, PaymentChannel, { state: RootState }>(
    "personalDetails/changeMobileNumber",
    (channel, ThunkAPI) => {
        const { mobile, realm} = ThunkAPI.getState().personalDetails;
        const { email } = ThunkAPI.getState().user;
        return channel.changeMobileNumber(email, mobile.fullNumber, realm);
    }
);

const initialState = {
    submitDisabled: true,
    loading: false,
    processing: false,
    password: '',
    errors: {}
} as State;

const slice = createSlice({
    name: "personal-details",
    initialState: initialState,
    reducers: {
        updateDetails: (state, action: PayloadAction<PersonalDetails>) => {
            const payload = action.payload;
            state.givenName = payload.givenName ?? state.givenName;
            state.surname = payload.surname ?? state.surname;
            state.dob = payload.dob ?? state.dob;
            state.address = payload.address ?? state.address;
            state.city = payload.city ?? state.city;
            state.state = payload.state ?? state.state;
            state.postcode = payload.postcode ?? state.postcode;
            state.occupation = payload.occupation ?? state.occupation;
            state.industry = payload.industry ?? state.industry;
            state.realm = payload.realm ?? state.realm;
            state.taxId = payload.taxId ?? state.taxId;
            state.submitDisabled = ComputeSubmitDisabled(state);
            return state;
        },
        updateMobile: (state, action: PayloadAction<PhoneChangedEvent>) => {
            state.mobile = action.payload;
            state.submitDisabled = ComputeSubmitDisabled(state);
            return state;
        },
        updateDeviceId: (state, action: PayloadAction<string>) => {
            state.deviceId = action.payload;
            return state;
        },
        updateW88DeviceId: (state, action: PayloadAction<string>) => {
            state.w88DeviceId = action.payload;
            return state;
        },
        updatePassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
            return state;
        },
        checkErrors: (state, action: PayloadAction<string | undefined>) => {
            const errors = state.errors;
            if (action.payload === 'mobile' || action.payload === undefined || errors.mobile) {
                if (IsNullOrEmpty(state.mobile?.fullNumber)) {
                    errors.mobile = "Please enter your mobile phone number";
                } else if (!state.mobile?.isValid) {
                    errors.mobile = "Please enter a valid phone number";
                } else {
                    delete errors.mobile;
                }
            }

            if (action.payload === 'givenName' || action.payload === undefined || errors.givenName) {
                if (IsNullOrEmpty(state.givenName)) {
                    errors.givenName = "Please enter your first name";
                } else {
                    delete errors.givenName;
                }
            }

            if (action.payload === 'surname' || action.payload === undefined || errors.surname) {
                if (IsNullOrEmpty(state.surname)) {
                    errors.surname = "Please enter your last name";
                } else {
                    delete errors.surname;
                }
            }

            if (action.payload === 'dob' || action.payload === undefined || errors.dob) {
                if (IsNullOrEmpty(state.dob)) {
                    errors.dob = "Please enter a valid date of birth";
                } else {
                    delete errors.dob;
                }
            }

            if (action.payload === 'address' || action.payload === undefined || errors.address) {
                if (IsNullOrEmpty(state.address)) {
                    errors.address = "Please enter your address";
                } else {
                    delete errors.address;
                }
            }

            if (action.payload === 'city' || action.payload === undefined || errors.city) {
                if (IsNullOrEmpty(state.city)) {
                    errors.city = "Please enter your city";
                } else {
                    delete errors.city;
                }
            }

            if (action.payload === 'state' || action.payload === undefined || errors.state) {
                if (IsNullOrEmpty(state.state)) {
                    errors.state = "Please select a state";
                } else {
                    delete errors.state;
                }
            }

            if (action.payload === 'postcode' || action.payload === undefined || errors.postcode) {
                if (IsNullOrEmpty(state.postcode)) {
                    errors.postcode = "Please enter a postal code";
                } else {
                    delete errors.postcode;
                }
            }

            if (action.payload === 'occupation' || action.payload === undefined || errors.occupation) {
                if (IsNullOrEmpty(state.occupation)) {
                    errors.occupation = "Please enter your occupation";
                } else {
                    delete errors.occupation;
                }
            }

            if (action.payload === 'industry' || action.payload === undefined || errors.industry) {
                if (IsNullOrEmpty(state.industry)) {
                    errors.industry = "Please enter your industry";
                } else {
                    delete errors.industry;
                }
            }

            if (action.payload === 'taxId' || action.payload === undefined || errors.taxId) {
                if (IsNullOrEmpty(state.industry)) {
                    errors.industry = "Please enter your ";
                } else {
                    delete errors.industry;
                }
            }

            return state;
        },
        setProcessing: (state, action: PayloadAction<boolean>) => {
            state.processing = action.payload;
            return state;
        },
        setMobileValid: (state, action: PayloadAction<boolean>) => {
            state.mobile.isValid = action.payload;
            return state;
        },
    },
    extraReducers: builder => {
        builder.addCase(connected, (state, action) => {
            state.givenName = Objects.Coalesce(state.givenName, action.payload.givenName)?.toString();
            state.surname = Objects.Coalesce(state.surname, action.payload.lastName)?.toString();
            state.address = Objects.Coalesce(state.address, action.payload.street)?.toString();
            state.city = Objects.Coalesce(state.city, action.payload.city)?.toString();
            state.state = Objects.Coalesce(state.state, action.payload.state)?.toString();
            state.postcode = Objects.Coalesce(state.postcode, action.payload.postcode)?.toString();
            state.mobile = Objects.Coalesce(state.mobile, { value: action.payload.phone, fullNumber: action.payload.phone, isValid: action.payload.phone != null});
            state.dob = Objects.Coalesce(state.dob, action.payload.dateOfBirth)?.toString();
            state.returnUrl = Objects.Coalesce(state.returnUrl, action.payload.returnUrl)?.toString();
            state.occupation = Objects.Coalesce(state.occupation, action.payload.occupation)?.toString();
            state.industry = Objects.Coalesce(state.industry, action.payload.industry)?.toString();
            state.submitDisabled = ComputeSubmitDisabled(state);
            state.realm = Objects.Coalesce(state.realm, action.payload.realm)?.toString();
        });
    }
});

function ComputeSubmitDisabled(details: State): boolean {
    return !details.mobile?.isValid ||
        IsNullOrEmpty(details.givenName) ||
        IsNullOrEmpty(details.surname) ||
        IsNullOrEmpty(details.dob) ||
        IsNullOrEmpty(details.address) ||
        IsNullOrEmpty(details.city) ||
        IsNullOrEmpty(details.state) ||
        IsNullOrEmpty(details.postcode) ||
        IsNullOrEmpty(details.occupation) ||
        IsNullOrEmpty(details.industry);
}

export const {
    updateDetails,
    updateMobile,
    updateDeviceId,
    updateW88DeviceId,
    updatePassword,
    checkErrors,
    setProcessing,
    setMobileValid
} = slice.actions;

export default slice.reducer;
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import * as strings from '../strings';
import {PaymentChannel} from "../../features/PaymentProvider";
import {RootState} from "../store";
import {connected} from "../actions";
import { UserVerifiedResponse } from '../types';

export const startSession = createAsyncThunk<void, PaymentChannel, { state: RootState }>(
    "user/startSession",
    (channel, thunkApi) => {
        const user = thunkApi.getState().user;
        return channel.startSession(user.email ?? "", user.country ?? "");
    });

export const LoadIsVerified = createAsyncThunk<UserVerifiedResponse | undefined, PaymentChannel, { state: RootState }>(
    "customer/verified",
    (channel, ThunkAPI) => {
        return channel.isVerified();
    }
);

export const LoadIsPhoneVerified = createAsyncThunk<boolean | undefined, PaymentChannel, { state: RootState }>(
    "customer/phoneVerified",
    (channel, ThunkAPI) => {
        return channel.isPhoneVerified();
    }
);

export const ChangeEmail = createAsyncThunk<void | undefined, PaymentChannel, { state: RootState }>(
    "customer/changeEmail",
    (channel, ThunkAPI) => {
        const {email} = ThunkAPI.getState().user;
        return channel.changeEmail(email);
    }
);

interface UserState {
    email?: string | undefined,
    country?: string | undefined,
    submitDisabled: boolean,
    signingIn: boolean,
    connected: boolean,
    sessionStarted: boolean,
    verified: boolean | undefined,
    enabled: boolean | undefined,
    errors: {
        email?: string | undefined,
        country?: string | undefined
    },
    phoneVerified: boolean | undefined,
}

const initialState = {
    submitDisabled: true,
    signingIn: false,
    connected: false
} as UserState;

function IsSubmitDisabled(connected: boolean, signingIn: boolean, email: string | undefined, country: string | undefined): boolean {
    return !connected || signingIn || strings.IsNullOrEmpty(email) || !IsEmailValid(email) || strings.IsNullOrEmpty(country);
}

function IsEmailValid(email: string | undefined): boolean{
    const emailRegExp = new RegExp('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$');
    return emailRegExp.test(email ?? "");
}

const slice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        updateEmail: (state, action: PayloadAction<string>) => {
            const email = action.payload;
            state.email = email;
            state.submitDisabled = IsSubmitDisabled(state.connected, state.signingIn, email, state.country);
            return state;
        },
        updateCountry: (state, action: PayloadAction<string>) => {
            const country = action.payload;
            state.country = country;
            state.submitDisabled = IsSubmitDisabled(state.connected, state.signingIn, state.email, country);
            return state;
        },
        checkErrors: (state, action: PayloadAction<string | undefined>) => {
            state.errors = {};
            if (action.payload === 'email' || action.payload === undefined) {
                if (strings.IsNullOrEmpty(state.email)) {
                    state.errors.email = "Please enter your email address";
                } else {
                    if (!IsEmailValid(state.email ?? "")) state.errors.email = "Please enter a valid email address";
                }
            }

            if ((action.payload === 'country' || action.payload === undefined)
                && strings.IsNullOrEmpty(state.country)) state.errors.country = "Please select a country";
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(connected, (state, action) => {
            state.connected = true;
            state.email = state.email ?? action.payload.email;
            state.country = state.country ?? action.payload.country;
            state.submitDisabled = IsSubmitDisabled(true, state.signingIn, state.email, state.country);
        });
        builder.addCase(startSession.pending, (state) => {
            state.signingIn = true;
            return state;
        });
        builder.addCase(startSession.fulfilled, (state) => {
            state.signingIn = false;
            return state;
        });
        builder.addCase(startSession.rejected, (state, action) => {
            state.signingIn = false;
            return state;
        });
        builder.addCase(LoadIsVerified.fulfilled, (state, action) => {
            const payload = action.payload;
            state.verified = payload && !!payload.isIdVerified;
            state.enabled = payload && !!payload.isEnabled;
            return state;
        });
        builder.addCase(LoadIsVerified.rejected, (state) => {
            state.verified = false;
            state.enabled = undefined;
            return state;
        });
        builder.addCase(LoadIsPhoneVerified.fulfilled, (state, action) => {
            const payload = action.payload;
            state.phoneVerified = payload;
            return state;
        });
        builder.addCase(LoadIsPhoneVerified.rejected, (state) => {
            state.phoneVerified = false;
            return state;
        });
    }
});

export const {
    updateCountry,
    updateEmail,
    checkErrors
} = slice.actions

export default slice.reducer;
import { cryptoCurrencies, CryptoCurrency, CryptoDictionary } from "../app/types";
import WalletAddressValidator from "multicoin-address-validator";

interface Address {
    isValid: (address: string) => CryptoDictionary<boolean>,
    isValidAny: (address: string | CryptoDictionary<boolean>) => boolean,
    type: (address: string) => CryptoCurrency | null
}

const Address = {
    isValid(address: string) {
        const result = {} as CryptoDictionary<boolean>;
        for (const c of cryptoCurrencies) {
            const val : CryptoCurrency = c;
            result[val] = WalletAddressValidator.validate(address, val, "both");
        }
        return result;
    },
    isValidAny(address: string | CryptoDictionary<boolean>) {
        for (const c of cryptoCurrencies) {
            const val : CryptoCurrency = c;
            if ((typeof address === "string" && WalletAddressValidator.validate(address, val, "both"))
                || (typeof address !== "string" && address[val]))
                return true;
        }

        return false;
    },
    type(address: string) {
        for(const c of cryptoCurrencies) {
            const val : CryptoCurrency = c;
            if (WalletAddressValidator.validate(address, val, "both"))
                return val;
        }

        return null;
    }
} as Address;

export default Address;

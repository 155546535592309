import * as React from 'react';
import {createContext, ReactNode} from 'react';
import * as signalR from "@microsoft/signalr";
import * as forge from 'node-forge';
import {
    Account,
    ChallengeMessage,
    Country,
    CreditCard,
    CryptoCurrency,
    CryptoDictionary,
    Currency,
    EmptyPaymentDetail,
    LimitSpecifier,
    MerchantRequest,
    Method,
    MethodLimit,
    MethodType,
    NamedAccount,
    NetworkFeeResponse,
    PaymentResponse,
    PersonalDetails,
    QuoteMessage,
    QuoteResponse,
    ResourceSpecifier,
    ResourceTag,
    SessionUpdateMessage,
    TaggedResource,
    UserVerifiedResponse
} from "../../app/types";
import {IHttpConnectionOptions} from "@microsoft/signalr/src/IHttpConnectionOptions";
import {useAppDispatch} from "../../app/hooks";
import {challenge, LoadIsEnabled, maintenance, paymentResponse, sessionUpdate} from "../../app/slices/Navigation";
import {quote} from "../../app/slices/Quote";
import * as jose from 'jose';
import {connected} from "../../app/actions";

const PaymentChannelContext = createContext<PaymentChannel | undefined>(undefined);

export {PaymentChannelContext}

export class PaymentChannel {
    connection: signalR.HubConnection;

    constructor(connection: signalR.HubConnection) {
        this.connection = connection;
    }

    startSession(email: string | undefined, country: string | undefined): Promise<void> {
        return this.connection.send("startSession", email, country);
    }

    getMethods(): Promise<Method[]> {
        return this.connection.invoke<Method[]>("getMethods");
    }

    getEnabledCryptoCurrencies(): Promise<CryptoDictionary<string>> {
        return this.connection.invoke<CryptoDictionary<string>>("getEnabledCryptoCurrencies");
    }

    getCurrency(): Promise<Currency> {
        return this.connection.invoke<Currency>("getCurrency");
    }

    getAvailableBuyAndSendCountries(): Promise<Country[]> {
        return this.connection.invoke<Country[]>("getAvailableBuyAndSendCountries");
    }

    getLimit(method: MethodType, asset: CryptoCurrency | undefined): Promise<MethodLimit> {
        return this.connection.invoke<MethodLimit>("getLimit", method, asset);
    }

    getQuote(sellcurrencyamount: number, sellcurrency: string, purchasecurrencyamount: number, purchasecurrency: string, type: string): Promise<QuoteResponse> {
        return this.connection.invoke<QuoteResponse>("quotation", sellcurrencyamount, sellcurrency, purchasecurrencyamount, purchasecurrency, type);
    }

    getEstimatedNetworkFee(assetId: string, rate: number, currency: string): Promise<NetworkFeeResponse> {
        return this.connection.invoke<NetworkFeeResponse>("estimatedNetworkFee", assetId, rate, currency);
    }

    isVerified(): Promise<UserVerifiedResponse> {
        return this.connection.invoke<UserVerifiedResponse>("isVerified");
    }

    isPhoneVerified(): Promise<boolean> {
        return this.connection.invoke<boolean>("isPhoneVerified");
    }

    isEnabled(): Promise<boolean> {
        return this.connection.invoke<boolean>("isEnabled");
    }

    isCountryAllowed(): Promise<boolean> {
        return this.connection.invoke<boolean>("isCountryAllowed");
    }

    isAllowedBuyAndSend(): Promise<boolean> {
        return this.connection.invoke<boolean>("isAllowedBuyAndSend");
    }

    getResource(url: string, tag: ResourceTag | undefined): Promise<TaggedResource> {
        return new Promise<TaggedResource>((resolve, reject) => {
            this.connection.invoke<string>("getResource", url)
                .then(data => {
                    resolve({ data: data, tag: tag });
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    calculateTotalAmount(method: MethodType, requestAmount: number): Promise<string> {
        return this.connection.invoke<string>("calculateTotalAmount", method, requestAmount);
    }

    async requestPayment(method: MethodType | undefined, amount: number | undefined, payee: string | undefined, asset: CryptoCurrency | undefined, details: CreditCard | Account | NamedAccount | EmptyPaymentDetail | undefined, deviceId: string | undefined): Promise<PaymentResponse> {
        let pem = await this.connection.invoke<string>("getPublicKey");
        const publicKey = forge.pki.publicKeyFromPem(pem);
        const payload = details ? forge.util.encode64(publicKey.encrypt(JSON.stringify(details), 'RSAES-PKCS1-V1_5')) : null;
        return this.connection.invoke<PaymentResponse>("requestPayment", method, amount, payee, asset, payload, deviceId);
    }

    authorize(userId: string, code: string): Promise<void> {
        return this.connection.invoke<void>("authorize", userId, code);
    }

    sendNewCode(userId: string): Promise<void> {
        return this.connection.invoke<void>("resendCode", userId);
    }

    authorizeEmailCode(userId: string, code: string): Promise<void> {
        return this.connection.invoke<void>("authorizeEmailCode", userId, code);
    }

    sendNewEmailCode(email: string, realm: string): Promise<void> {
        return this.connection.invoke<void>("resendEmailCode", email, realm);
    }

    register(details: PersonalDetails): Promise<void> {
        return this.connection.invoke<void>("register", details);
    }

    getRaiseLimitUrl(): Promise<string> {
        return this.connection.invoke<string>("getRaiseLimitUrl");
    }

    cancelPayment(): Promise<void> {
        return this.connection.invoke<void>("cancelPayment");
    }

    tryAnotherMethod(): Promise<void> {
        return this.connection.invoke<void>("tryAnotherMethod");
    }

    setPassword(password: string | undefined): Promise<void> {
        return this.connection.invoke<string>("getPublicKey")
            .then(pem => {
                const publicKey = forge.pki.publicKeyFromPem(pem);
                const buffer = forge.util.createBuffer(password ?? "", "utf8");
                const payload = password ? forge.util.encode64(publicKey.encrypt(buffer.getBytes(), 'RSAES-PKCS1-V1_5')) : null;
                return this.connection.send("setPassword", payload);
            });
    }

    checkPassword(password: string | undefined): Promise<void> {
        return this.connection.invoke<string>("getPublicKey")
            .then(pem => {
                const publicKey = forge.pki.publicKeyFromPem(pem);
                const buffer = forge.util.createBuffer(password ?? "", "utf8");
                const payload = forge.util.encode64(publicKey.encrypt(buffer.getBytes(), 'RSAES-PKCS1-V1_5'));
                return this.connection.send("checkPassword", payload);
            });
    }

    changeMobileNumber(email: string | undefined, mobileNumber: string | undefined, realm: string | undefined): Promise<void> {
        return this.connection.send("changeMobileNumber", email, mobileNumber, realm);
    }

    changeEmail(email: string | undefined): Promise<void> {
        return this.connection.send("changeEmail", email);
    }
}

export interface PaymentChannelEnvelope {
    channel: PaymentChannel,
    parameters: ResourceSpecifier | LimitSpecifier | undefined
}

interface PaymentProviderOptions {
    url?: string | null | undefined,
    token?: string | null | undefined,
    children?: ReactNode | undefined
}

function PaymentProvider({url, token, children}: PaymentProviderOptions) {
    const dispatch = useAppDispatch();
    let ws: PaymentChannel | undefined;

    const initConnection = (connection: signalR.HubConnection) => {
        connection.start()
            .then(() => {
                if (ws)
                    dispatch(LoadIsEnabled(ws));

                let claims = token
                    ? jose.decodeJwt(token)
                    : undefined;

                dispatch(connected({
                    application: claims ? claims['w88.application'] : undefined,
                    amount: claims ? claims['w88.amount'] : undefined,
                    currency: claims ? claims['w88.currency'] : undefined,
                    payee: claims ? claims['w88.payee'] : undefined,
                    asset: claims ? claims['w88.asset'] : undefined,
                    givenName: claims ? claims['w88.givenName'] : undefined,
                    lastName: claims ? claims['w88.lastName'] : undefined,
                    email: claims ? claims['w88.email'] : undefined,
                    phone: claims ? claims['w88.phone'] : undefined,
                    dateOfBirth: claims ? claims['w88.dob'] : undefined,
                    street: claims ? claims['w88.addressStreet'] : undefined,
                    city: claims ? claims['w88.addressCity'] : undefined,
                    state: claims ? claims['w88.addressState'] : undefined,
                    country: claims ? claims['w88.addressCountry'] : undefined,
                    countryName: claims ? claims['w88.addressCountryName'] : undefined,
                    postcode: claims ? claims['w88.addressPostCode'] : undefined,
                    returnUrl: claims ? claims['w88.returnUrl'] : undefined,
                    occupation: claims ? claims['w88.occupation'] : undefined,
                    industry: claims ? claims['w88.industry'] : undefined,
                    skipLogin: claims ? claims['w88.bypassLoginPage'] === 'True' : undefined,
                    enableEditPayeeInput: claims ? claims['w88.enableEditPayeeInput'] === 'True' : undefined,
                    enableEditAmountInput: claims ? claims['w88.enableEditAmountInput'] === 'True' : undefined,
                    selectedCountryIsNotRegisterCountry: claims ? claims['w88.selectedCountryIsNotRegisterCountry'] === 'True' : undefined,
                    realm: claims ? claims['w88.realm'] : undefined
                } as MerchantRequest));
            })
            .catch((e) => {
                console.log(e)
                dispatch(maintenance());
            });
    }

    if (!ws) {
        let connection = new signalR.HubConnectionBuilder()
            .withUrl((url ?? "https://wallet88.com/api") + "/pos", {
                accessTokenFactory: () => token
            } as IHttpConnectionOptions)
            .withAutomaticReconnect()
            .build();

        connection.on("challenge", (message: ChallengeMessage) => {
            dispatch(challenge(message));
        });

        connection.on("response", (message: PaymentResponse) => {
            dispatch(paymentResponse(message));
        });

        connection.on("quote", (message: QuoteMessage) => {
            dispatch(quote(message));
        });

        connection.on("sessionUpdate", (message: SessionUpdateMessage) => {
            connection.stop()
                .finally(() => {
                    dispatch(sessionUpdate(message));
                });
        });

        initConnection(connection);

        ws = new PaymentChannel(connection);
    }
    return (<PaymentChannelContext.Provider value={ws}>{children}</PaymentChannelContext.Provider>);
}

export default PaymentProvider;
